main {
  height: 100%;
  flex-direction: column;
  display: flex;
}

section {
  display: grid;
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  flex: 1;

  > * {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    text-align: center;
  }
}

.brand {
  &-container {
    svg {
      max-width: 25rem;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: bolder;
    }
  }

  &-subtitle {
    font-size: 2rem;
    padding: 2rem 0;
  }
}

.solutions {
  svg {
    height: 100%;

    @media (min-width: 992px) {
      max-width: 45vw;
    }
  }

  &-subtitle {
    font-size: 2.5rem;
    padding: 2rem 0;

    a {
      margin-right: 0.5rem;
    }
  }
}

footer {
  background: white;

  border-top: 0.25rem solid #ff6600;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.45);

  display: grid;

  grid-template-columns: 1fr 1fr;

  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    max-height: 4rem;
  }

  align-items: center;

  padding: 1rem;

  > * {
    flex: 1;

    & + * {
      margin-top: 1rem;
      @media (min-width: 992px) {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: middle;

    max-width: 70vw;
    height: 4rem;
    @media (min-width: 992px) {
      max-width: auto;
      max-height: 4rem;
    }

    object-fit: contain;
  }
}

i {
  color: #ff6600;
  font-family: "Kalam";
  font-style: normal;
}

a {
  color: #ff6600;
}
